import { Auth } from "@aws-amplify/auth"
import { API } from "@aws-amplify/api"
Auth.configure({
  identityPoolId: "us-east-1:6eed01ad-238d-41ca-aef5-bf4c79ac97b4",
  region: "us-east-1",
  userPoolWebClientId: "507umh7bfng7h2jvp46o5eu0ck",
  userPoolId: "us-east-1_WoSi8CAWY",
})

API.configure({
  aws_appsync_graphqlEndpoint:
    "https://ew2cfycq5re6tnwoczv5dxfe3m.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AWS_IAM",
})

export { API }
export default Auth